import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Grid, Row, Col } from 'react-flexbox-grid'

import styles from './flare-site.module.scss'

import { Parallax } from 'react-scroll-parallax'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import Tablet from '../../components/Preview/Tablet'
import Preview from '../../components/Preview'
import WorkTitle from '../../components/WorkTitle'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'Flare Site',
  client: 'Flare',
  path: '/works/web/flare-site/',
  date: '2014-11-05',
  image: 'flare-site-demo',
  related: '/works/identity/flare-logo/',
  type: 'Site',
}

const related = [
  {
    title: frontmatter.type,
    href: frontmatter.path,
  },
  {
    title: 'Identity',
    href: frontmatter.related,
  },
]

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/flare/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <WorkTitle title={frontmatter.title} related={related} />
          <p className="aligncenter text-center">
            Design and develop the modern digital agency website.
          </p>
          <Grid>
            <Row>
              <Col xs={12} sm={10} smOffset={1}>
                <Tablet
                  fluid={findImage(images, 'flare-home-ipad@2x')}
                  alt="Flare home page"
                  shadow
                />
              </Col>
            </Row>
          </Grid>
        </div>

        <div className={styles.heroBg}>
          <Img fluid={findImage(images, 'site-hero-bg')} alt="" />
        </div>
      </div>

      <section className={styles.services}>
        <Grid>
          <Row>
            <Col xs={12} sm={9} smOffset={2}>
              <p>
                Flare helps startups and small businesses to succeed online.
              </p>
              <p>
                They provide analytics and marketing strategy services. Help
                with search engine optimization and develop a strategic
                marketing plan.
              </p>
            </Col>
          </Row>
        </Grid>

        <div className={styles.servicesWrapper}>
          <div className={styles.service}>
            <Parallax y={[0, 20]}>
              <Img
                fluid={findImage(images, 'service-3')}
                alt="Design and Development"
              />
            </Parallax>
          </div>
          <div className={styles.service}>
            <Parallax y={[30, 0]}>
              <Img
                fluid={findImage(images, 'service-2')}
                alt="SEO and Marketing"
              />
            </Parallax>
          </div>
          <div className={styles.service}>
            <Parallax y={[60, -20]}>
              <Img
                fluid={findImage(images, 'service-1')}
                alt="Analytics and reporting"
              />
            </Parallax>
          </div>
        </div>
      </section>

      <br />
      <br />

      <Grid>
        <Row>
          <Col xs={12} sm={9} smOffset={2}>
            <p>
              Flare uses a value-based pricing model. Price reflects the value
              delivered to the client, not the time spent completing the job.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={10} smOffset={1}>
            <Img
              fluid={findImage(images, 'pricing')}
              alt="Flare pricing table"
            />
          </Col>
        </Row>
      </Grid>

      <br />
      <br />
      <br />

      <Grid>
        <Row>
          <Col xs={12} sm={8} smOffset={2}>
            <Preview
              fluid={findImage(images, 'project')}
              url="Project page"
              alt="Completed project page"
            />
          </Col>
        </Row>
      </Grid>

      <br />
      <br />
      <br />

      <Img fluid={findImage(images, 'flare-form')} alt="Flare Form" />

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
